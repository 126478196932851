@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

#navbar {
    height: 80px;
    width: 100%;

    display: flex;
    justify-content: flex-end;
    place-items: center;
    position: relative;
}

.navButton {
    height: 40px;
    margin-right: 20px;

    border-radius: 5px;
    border: none;
    background-color: #FF4A49;

    color: white;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 2px;

    transition: all 200ms;
    cursor: pointer;

    display: flex;
    justify-content: center;
    place-items: center;
}

.navButton:hover, .navButton:hover * {
    background-color: white;
    color: #FF4A49;
}

.navButton:hover svg {
    color: #FF4A49;
    stroke: #FF4A49;
}

.status_button {
    width: 100px;
}

.login_button {
    width: 50px;
}

.LoginForm {
    position: absolute;
    background: linear-gradient(45deg, #FB4F40,#FF4A49);
    height: 15vh;
    width: 20vw;
    top: 80px;
    right: 20px;
    padding: 10px 30px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    z-index: 10;
}

.LoginForm h1 {
    font-size: 30px;
    color: white;
    font-family: 'Oswald', sans-serif;
}

#NamenInput {
    height: 40px;
    border-radius: 6px;
    margin-top: 20px;
    font-size: 17px;
    border: none;
    font-family: 'Oswald', sans-serif;
    text-indent: 10px;
}

#NamenInput:focus, #NamenInput:active {
    outline: none;
    font-family: 'Oswald', sans-serif;
} 