@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap");

#BerlinWrapper * {
    box-sizing: border-box;
    font-family: "Montserrat";
}

#BerlinWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
}

.BerlinTimeLine {
    width: 80%;
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
}

.BerlinTimeLine ul {
    list-style: none;
}

.BerlinTimeLine ul li {
    padding: 20px;
    background-color: #1e1f22;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
}

.BerlinTimeLine ul li:last-child {
    margin-bottom: 0;
}

.BerlinTimeLine-content h1 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
}

.BerlinTimeLine-content p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
}

.BerlinTimeLine-content .date {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
    letter-spacing: 2px;
}

@media only screen and (min-width: 768px) {
    .BerlinTimeLine::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 100%;
        background-color: gray;
    }

    .BerlinTimeLine ul li {
        width: 50%;
        position: relative;
        margin-bottom: 50px;
    }

    .BerlinTimeLine ul li:nth-child(odd) {
        float: left;
        clear: right;
        transform: translateX(-30px);
        border-radius: 10px 0px 10px 10px;
    }

    .BerlinTimeLine ul li:nth-child(even) {
        float: right;
        clear: left;
        transform: translateX(30px);
        border-radius: 0px 10px 10px 10px;
    }

    .BerlinTimeLine ul li::before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: gray;
        top: 0px;
    }

    .BerlinTimeLine ul li:nth-child(odd)::before {
        transform: translate(50%, -50%);
        right: -30px;
    }

    .BerlinTimeLine ul li:nth-child(even)::before {
        transform: translate(-50%, -50%);
        left: -30px;
    }

    .BerlinTimeLine-content .date {
        position: absolute;
        top: -30px;
    }

    .BerlinTimeLine ul li:hover::before {
        filter: brightness(2)
    }
}