.BehindTheScenes {
    display: flex;
    overflow: hidden;
    justify-content: center;
    place-items: center;
    padding: 50px;
}

.leftB {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;

    justify-content: center;
    place-items: center;
}

.leftContent {
    width: 70%;
}

.leftB .leftContent h1 {
    color: white;
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    margin-bottom: 30px;
}

.leftB .leftContent p {
    font-family: 'Oswald', sans-serif;
    font-size: 15px;
    color: white;
    line-height: 20px;
}

.rightB {
    max-width: 60%;
    margin: 30px;
    height: 70%;
    overflow-x: scroll;

    display: flex;
    justify-content: flex-start;
    place-items: center;
}

.ImageFullScreen {
    max-width: 100%;
    max-height: 750px;
    aspect-ratio: auto;
    border-radius: 10px;
    pointer-events: none;
    user-select: none;
}


.user {
    margin-top: 20px;
    pointer-events: none;
    user-select: none;
}


@media all and (max-width: 1000px) {
    .BehindTheScenes {
        flex-direction: column !important;
        justify-content: center;
        place-items: center;
        height: fit-content;
        padding-top: 40px;
        padding-bottom: 60px;
    }
    .leftContent {
        width: fit-content;
        max-width: 90%;
    }
    .leftB {
        height: fit-content;
        margin-top: 30px;
        width: 100%;
    }
    .rightB {
        margin: 0;
        margin-top: 30px;
        min-height: 40vh;
        max-width: unset;
        min-height: unset;

    }
    .OneImage {
        justify-content: center;
    }

}

@media all and (max-width: 650px) {
    .BehindTheScenes {
        padding: 20px;

    }
    .ImageFullScreen {
        margin-top: 30px;
    }
    .leftContent {
        width: 90%;
        text-align: center;
    }
    .rightB {
        width: 95%;
        margin: 0;
        max-width: none;
        min-height: unset;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
        display: none;
    }
    to {
        opacity: 1;
        display: block;
    }
}

@keyframes neueVorschaubilder {
    from {
        transform: translateX(30px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

