.StatusSeite {
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    place-items: center;
    justify-content: center;
    flex-direction: column;

    background: url("../../assets/Mauerfall2.jpg") no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.TopBanner {
    height: 20vh;
    width: 100%;

    display: grid;
    place-items: center;
    font-family: 'Oswald', sans-serif;

    position: relative;
}

.moveUp {
    background-position: center -200px;
}

.TopBanner h1 {
    font-size: 4rem;
    letter-spacing: 8px;
    color: white;
}

#signedinas {
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 10px;
}

.DokumenteWrapper {
    width: 100%;
    margin-top: 20px;
    display: grid;
    place-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.Dokumente {
    height: 50vh;
    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    justify-content: center;
    place-items: center;
    flex-wrap: wrap;
}

.Dokument {
    height: 150px;
    margin: 20px 20px;
    width: 40%;
    max-width: 555px;
    min-width: 400px;
    border-radius: 20px;
    background: #030c2f;
    transition: all var(--transition-time-short);
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.left {
    display: flex;
    place-items: center;
    justify-content: center;
    height: 100%;
}

.Vorschaubild {
    max-height: 85%;
    max-width: 100%;
    display: block;
    margin-left: 20px;
    margin-right: 20px;
}


.middle {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex: 1;
    width: 100%;

}

.middle>* {
    margin-left: 10px;
}

.middle h1 {
    font-size: 30px;
    font-family: 'Oswald', sans-serif;
    color: white;
}

.middle button {
    padding: 10px 20px;
    background-color: #FF4A49;
    outline: none;
    border: none;
    border-radius: 5px;
    letter-spacing: 1px;
    color: white;
    font-family: 'Oswald', sans-serif;
    width: fit-content;
    text-align: center;
    transition: all var(--transition-time-short);

}

.middle button:hover {
    color: #FF4A49;
    background-color: white;
}

.right {
    min-width: 30px;
}


/*----------------------------------------------------------*/
/*--------------------------TABLE---------------------------*/
/*----------------------------------------------------------*/

.InterviewTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: center;
    position: relative;

}

.FortschrittLabel {
    font-size: 30px;
    color: a;
    font-family: 'Oswald', sans-serif;
}

.contentArea {
    background-color: #252627;
    width: 100%;
    display: flex;
    justify-content: center;
    place-items: center;
}

.marginMore {
    margin-top: 30px;
    margin-bottom: 30px;
}

.Tabelle {
    width: 80%;
    aspect-ratio: auto;

    margin-top: 40px;
    margin-bottom: 40px;
}

table {
    width: 80%;
    text-align: center;
    background-color: #030c2f;
    min-height: 70vh;
    margin-top: 30px;
    margin-bottom: 30px;
}

thead tr *,
tbody tr * {
    color: white;
    border: 2px solid rgb(100, 100, 100);
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    vertical-align: middle;
}

tbody tr:hover td {
    border: none;
}

tbody tr:hover {
    transform: scale(1.05);
    background-color: #02061a;
}

tbody tr:hover td:first-child {
    border-radius: 10px 0px 0px 10px;
}

tbody tr:hover td:last-child {
    border-radius: 0px 10px 10px 0px;
}

tr {
    height: 50px;
}

#notbordered {
    border: none;
}

#notbordered:hover {
    fill: #00b341;
}

@media all and (max-width: 900px) {
    table {
        width: 100%;
        text-align: center;
        background-color: #030c2f;
        min-height: 70vh;
    }
}

@media all and (max-width: 800px) {
    table {
        width: 100%;
        text-align: center;
        background-color: #030c2f;
        min-height: 70vh;
    }
}

@media only screen and (max-width: 720px) {
    .TopBanner h1 {
        font-size: 1rem;
    }
}

@media all and (max-width: 887px) {
    .Dokument {
        min-width: 100px;
        width: 100%;
        margin: 0;
        margin-top: 20px;
    }

    .Dokumente {
        width: 100%;
        margin-bottom: 40px;
    }
}

@media all and (max-width: 440px) {
    .Vorschaubild {
        max-height: 80px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .Dokument {
        flex-direction: column;
        justify-content: space-evenly;
        min-height: 200px;
        height: fit-content;
    }

    .middle {
        place-items: center;
        margin-bottom: 10px;
    }

    .middle h1 {
        font-size: 20px;
    }

    .Tabelle {
        width: 95%;
    }

    thead tr *,
    tbody tr * {
        font-size: 15px;
    }
}