.topImpressum {
    justify-content: center;
    place-items: center;

    width: 100% !important;
    margin: 0 !important;

    flex-direction: column !important;
}

.IHeader {
    width: 50%;
    padding: 30px;
    padding-top: 0;
    padding-left: 0;
    font-size: 35px;
}

.ISpalten {
    width: 50%;
    padding: 20px;
    border-radius: 5px;
    background-size: 500% 500%;
    background-position: left;

    background: linear-gradient(90deg, #ee6352, purple, #ee6352);
    background-size: 300%;
}

.ISpalten:hover {
    background-image: linear-gradient(90deg, #ee6352, purple, #ee6352);
    animation: bg-animation 5s infinite;
}

.ISpalte {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.infos {
    color: white;
    font-family: 'Oswald', sans-serif;
    letter-spacing: 1.5px;
}

.ISpalten:hover {
    background-position: right;
}

@keyframes bg-animation {
  0% {background-position: left}
  50% {background-position: right}
  100% {background-position: left}
}

@media all and (max-width: 1200px) {
    .IHeader {
        width: 80%;
    }
    .ISpalten {
        width: 80%;
    }
}

@media all and (max-width: 560px) {
    .ISpalte {
        flex-direction: column;
        margin-bottom: 20px;
    }
    .cat {
        font-size: 25px;
    }
    .info {
        margin-top: 10px;
    }
}